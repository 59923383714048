<template>
    <div class="statique">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/plan-du-site">Plan du site</router-link>
            </div>
            <h2 class="titre">Plan du site</h2>
        </div>
        <div class="liens">
            <template v-for="route in routes">
                <router-link :key="'route-'+ route.path" v-if="!route.disabled" class="lien" :to="route.path">{{ route.name }}</router-link>
                <span class="lien" v-else :key="'route-'+ route.path">{{ route.name }}</span>
            </template>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "Mentions légales",
    computed: {
        ...mapGetters([
            'isLogged',
        ]),
        routes() {
            return this.$router.options.routes
                .filter(route => {
                    return route.path.indexOf(':') === -1 && route.meta && route.meta.sitemap !== undefined;
                })
                .map(route => {
                    let auth =      (route.meta.auth      && route.meta.auth      ===  this.isLogged) === false;
                    let guestOnly = (route.meta.guestOnly && route.meta.guestOnly === !this.isLogged) === false;
                    route.disabled = auth || guestOnly;
                    return route;
                });
        },
    },
}
</script>

<style scoped lang="scss">
.liens {
    display: flex;
    flex-direction:column;
    .lien {
        width:auto;
        margin: 0 auto 12px auto;
        font: normal normal normal 22px/31px Nunito;
        text-decoration-thickness: 2px;
        padding-left: 1em;
        &:not(span) {
            -webkit-text-decoration-color: black;
            text-decoration-color: black;
            color: black;
        };
    }
    & span.lien {
        -webkit-text-decoration-color: #707070;
        text-decoration-color: #707070;
        color: #707070;
    }

}
</style>